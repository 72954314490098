@import (reference) "../../styles/main";

@colorSet: {
  black: @color-black;
  white: @color-white;
  coolgray_50: @color-coolGray-50;
  coolgray_100: @color-coolGray-100;
  coolgray_200: @color-coolGray-200;
  coolgray_300: @color-coolGray-300;
  coolgray_400: @color-coolGray-400;
  coolgray_500: @color-coolGray-500;
  coolgray_600: @color-coolGray-600;
  coolgray_700: @color-coolGray-700;
  coolgray_800: @color-coolGray-800;
  coolgray_900: @color-coolGray-900;
  teal_50: @color-teal-50;
  teal_100: @color-teal-100;
  teal_200: @color-teal-200;
  teal_300: @color-teal-300;
  teal_400: @color-teal-400;
  teal_500: @color-teal-500;
  teal_600: @color-teal-600;
  teal_700: @color-teal-700;
  teal_800: @color-teal-800;
  teal_900: @color-teal-900;
  teal_1000: @color-teal-1000;
  blue_25: @color-blue-25;
  blue_50: @color-blue-50;
  blue_100: @color-blue-100;
  blue_200: @color-blue-200;
  blue_300: @color-blue-300;
  blue_400: @color-blue-400;
  blue_500: @color-blue-500;
  blue_600: @color-blue-600;
  blue_700: @color-blue-700;
  blue_800: @color-blue-800;
  blue_900: @color-blue-900;
  blue_1000: @color-blue-1000;
  pink_50: @color-pink-50;
  pink_100: @color-pink-100;
  pink_200: @color-pink-200;
  pink_300: @color-pink-300;
  pink_400: @color-pink-400;
  pink_500: @color-pink-500;
  pink_600: @color-pink-600;
  pink_700: @color-pink-700;
  pink_800: @color-pink-800;
  pink_900: @color-pink-900;
  pink_1000: @color-pink-1000;
  violet_50: @color-violet-50;
  violet_100: @color-violet-100;
  violet_200: @color-violet-200;
  violet_300: @color-violet-300;
  violet_400: @color-violet-400;
  violet_500: @color-violet-500;
  violet_600: @color-violet-600;
  violet_700: @color-violet-700;
  violet_800: @color-violet-800;
  violet_900: @color-violet-900;
  violet_1000: @color-violet-1000;
};

.SectionContainer {
  .section-Layout();
  gap: 4rem;
  @media @tablet-landscape {
    gap: 3rem;
  }
}

.Section {
  .main-Layout-section();
  position: relative;

  each(@colorSet, {
    &.@{key} { background-color: @value;}
  });

  &.blue_800,
  &.blue_900,
  &.blue_1000,
  &.violet_900,
  &.violet_1000 {
    .SectionTitle,
    .SectionHeading {
      color: @color-white;
    }

    .SectionSubtitle {
      color: @color-violet-100;
    }
    .SectionParagraph {
      color: @color-violet-200;
    }
  }
  &.blue_25_to_coolgray_200 {
    background: linear-gradient(180deg, @color-blue-25 50%, @color-coolGray-200 50.0001%);
  }
  &.blue_100_to_coolgray_100 {
    background: linear-gradient(180deg, @color-blue-100 50%, @color-coolGray-100 50.0001%);
  }

  &.blue_25_to_blue_200 {
    background: linear-gradient(180deg, @color-blue-25 50%, @color-blue-200 50.0001%);

    @media @tablet-landscape {
      background: @color-blue-200;
    }
  }

  &.coolgray_50_to_blue_200 {
    background: linear-gradient(180deg, @color-coolGray-50 50%, @color-blue-200 50.0001%);

    @media @tablet-landscape {
      background: @color-blue-200;
    }
  }

  &.blue_25_to_white {
    background: linear-gradient(180deg, @color-blue-25 50%, @color-white 50.0001%);
  }

  &.coolgray_50_to_coolgray_100 {
    background: linear-gradient(180deg, @color-coolGray-50 50%, @color-coolGray-100 50.0001%);
  }

  &.coolgray_100_to_coolgray_200 {
    background: linear-gradient(180deg, @color-coolGray-100 50%, @color-coolGray-200 50.0001%);
  }

  &.white_to_coolgray_100 {
    background: linear-gradient(180deg, @color-white 50%, @color-coolGray-100 50.0001%);
  }

  &.white_to_blue_200 {
    background: linear-gradient(180deg, @color-white 50%, @color-blue-200 50.0001%);
  }

  &.DoubledPadding {
    padding-bottom: @main-padding-bottom * 2;

    @media @mobile {
      padding-bottom: @main-padding-bottom-mobile * 2;
    }
  }

  &.RemoveTopPadding {
    padding-top: 0;
  }
  &.RemoveBottomPadding {
    padding-bottom: 0;
  }

  &.TypeNoPadding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.TypeOneCol {
    .SectionColWrapper {
      gap: 4rem;
      flex-direction: column;
      width: 100%;
    }
    .SectionCol {
      align-items: center;
      width: 100%;
    }
  }

  &.TypeTwoCol {
    text-align: left;
    .SectionMasthead + .SectionColWrapper {
      //    margin-top: 2rem;
    }
    .SectionColWrapper {
      gap: 6rem;

      .SectionCol {
        flex: 1 1 0px;
        display: flex;
        justify-content: flex-start;
        > .SectionImage {
          align-self: flex-start;
        }
        .SectionHeading {
          margin: 0;
        }
        .SectionParagraph {
          margin: 0;
        }
        &:last-child {
          flex: 0 0 36rem;
          justify-content: flex-end;
        }
      }

      @media @tablet-landscape {
        flex-direction: column-reverse;
        gap: 4rem;
        text-align: center;

        a {
          margin: auto;
        }

        .SectionCol,
        .SectionCol:last-child {
          flex: 1 1 auto;
          width: 100%;
        }
        .SectionImage {
          width: 50%;
          max-width: 50%;
          margin: auto;
        }
      }

      @media @mobile {
        text-align: left;
        .SectionImage {
          width: 100%;
          max-width: 100%;
          margin-left: 0;
        }
      }
    }

    &.OverrideReversal {
      .SectionColWrapper {
        .SectionCol:first-child {
          flex: 0 0 36rem;
        }
        .SectionCol:last-child {
          flex: 1 1 0px;
        }
        @media @tablet-landscape {
          flex-direction: column;
          .SectionCol:first-child,
          .SectionCol:last-child {
            flex: 1 1 auto;
            width: 100%;
          }
        }
      }
    }
  }

  &.Reverse {
    .SectionColWrapper {
      flex-direction: row-reverse;
      .SectionCol {
        justify-content: flex-end;
        > .SectionImage {
          align-self: flex-end;
        }

        &:last-child {
          justify-content: flex-start;
        }
      }
      @media @tablet-landscape {
        flex-direction: column-reverse;
        gap: 4rem;

        .SectionCol,
        .SectionCol:last-child {
          flex: 1;
          width: 100%;
        }
        .SectionImage {
          max-width: 50%;
          margin: auto;
        }
      }

      @media @mobile {
        text-align: left;
        .SectionImage {
          width: 100%;
          max-width: 100%;
          margin-left: 0;
        }
      }
    }
  }

  &.ReverseMobile {
    .SectionColWrapper {
      @media @tablet-landscape {
        flex-direction: column;
        gap: 4rem;
      }
    }
  }

  &.TypeThreeCol {
    text-align: left;
    .SectionMasthead + .SectionColWrapper {
      //  margin-top: 2rem;
    }
    .SectionColWrapper {
      gap: 2rem;
      .SectionCol {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-self: stretch;
      }

      @media @tablet-landscape {
        flex-direction: column;
        gap: 1.25rem;
        .SectionCol {
          flex: 1 1 auto;
          width: 100%;
        }
      }
    }
  }

  &.ColWidthsEqual {
    .SectionColWrapper {
      .SectionCol,
      .SectionCol:last-child {
        flex: 1;
      }
    }
    @media @tablet-landscape {
      flex-direction: column;
      gap: 4rem;
      align-items: center;
      text-align: center;

      .SectionCol,
      .SectionCol:last-child {
        flex: 1;
        width: 100%;
      }
      .SectionImage {
        max-width: 75%;
        margin: auto;
      }
    }

    @media @mobile {
      text-align: left;
    }
  }

  &.OverlayHero {
    .SectionColWrapper {
      margin-top: -1 * (@hero-padding-bottom + 2rem);
    }
  }
}

.SectionMasthead {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
  @media @mobile {
    align-items: flex-start;
  }
}

.SectionTitle {
  .text-heading-2();
  max-width: @layout-hero-heading-text-max-width;

  @media @mobile {
    text-align: left;
  }
}

.SectionSubtitle {
  .text-subheading-2();
  margin: 0;
  max-width: @layout-hero-heading-text-max-width;
  @media @mobile {
    text-align: left;
  }
}

.SectionHeading.h2,
h2.SectionHeading {
  .text-heading-2();
  max-width: @layout-text-max-width;
}

.SectionHeading.h3,
h3.SectionHeading {
  .text-heading-3();
  max-width: @layout-text-max-width;

  > strong {
    font-weight: 600;
  }
}

.SectionHeading.h4,
h4.SectionHeading {
  .text-heading-4();
  max-width: @layout-text-max-width;
}

.SectionParagraph {
  .text-paragraph();
  display: inline-flex;
  gap: 1.5rem;

  @media @tablet-landscape {
    justify-content: center;
  }
  @media @tablet {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  @media @mobile {
    display: flex;
    width: 100%;
    align-items: flex-start;
  }

  &.SectionSubheading {
    .text-subheading-2();
    max-width: @layout-text-max-width;
  }
  + a {
    margin-top: 1rem;
  }
  > span {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    svg {
      color: @color-violet-400;
    }
  }
}

.SectionList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3rem;

  &.SectionListCard {
    .card-small-Layout();
    border-color: transparent;
  }
  each(@colorSet, {
    &.@{key} { background-color: @value;}
  });

  &.SectionListSmall {
    gap: 1rem;
    .SectionListItem {
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      gap: 1rem;

      @media @tablet-landscape {
        flex-direction: row;
        .SectionListItemContent {
          text-align: left;
          align-items: flex-start;
          width: 100%;
        }
      }
    }
  }
}

.SectionListItem {
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 1.5rem;

  .SectionListItemIcon {
    flex: 0 0 2rem;
    width: 2rem;
    height: 2rem;
    margin: 0;
    background-color: @color-blue-50;
    position: relative;
    border-radius: 50%;
    z-index: 0;
    font-size: 1.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: "";
      height: 1.25rem;
      width: 1.25rem;
      background-color: @color-blue-100;
      border-radius: 50%;
      position: absolute;
      top: 0.375rem;
      left: 0.375rem;
      z-index: -1;
    }
    > svg {
      color: @color-blue-800;
      z-index: 9;
    }
  }
  .SectionHeading,
  .SectionParagraph {
    margin: 0;
  }
  h3.SectionHeading {
    line-height: 2rem;
  }

  .SectionListItemContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & li {
      &::marker {
        color: @color-blue-800;
      }

      u {
        font-size: 1.2rem;
        font-weight: 600;
        color: @color-blue-800;
      }
    }

    @media @tablet-landscape {
      align-items: center;
      text-align: center;
      width: 100%;
    }

    @media @mobile {
      align-items: flex-start;
      text-align: left;
    }
  }

  @media @tablet-landscape {
    flex-direction: column;
    align-items: center;
  }
  @media @mobile {
    align-items: flex-start;
  }
}

.SectionButtonGridWrapper {
  width: 100%;
}

.SectionDataGridButtonWrapper {
  margin-top: 1.5rem;
  text-align: center;
}

.SectionButtonGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-flow: dense;
  height: 100%;
  column-gap: 2rem;
  row-gap: 2rem;

  &.GridSizeTwo {
    grid-template-columns: 1fr 1fr;
  }

  &.GridSizeFour {
    grid-template-columns: 1fr 1fr;
    @media @tablet-landscape {
      grid-template-columns: 1fr 1fr;
    }

    @media @tablet {
      grid-template-columns: 1fr;
    }
  }

  @media @tablet-landscape {
    grid-template-columns: 1fr 1fr;
  }

  @media @tablet {
    grid-template-columns: 1fr;
  }

  .SectionButtonGridButton {
    .card-small-Layout();
    .card-hoverable-Layout();
    display: flex;
    height: 100%;
    text-align: center;
    align-items: stretch;
    > span {
      min-height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    each(@colorSet, {
      &.@{key} { background-color: @value; border-color: transparent; &:hover { border-color: @color-indigo-100; }}
    });

    &:hover {
      .SectionButtonGridButtonLabel {
        color: @color-primary-1000;
        background: @color-coolGray-300;
      }
    }

    &.blue_50 {
      .SectionButtonGridButtonIcon {
        background: transparent;
      }
      &:hover {
        background-color: @color-blue-100;
      }
    }

    .SectionParagraph {
      flex: 1;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .SectionButtonGridButtonIcon {
    background-color: @color-coolGray-50;
    border-radius: 50%;
    padding: 0.625rem;
    flex: 0 0 5rem;
    width: 5rem;
    height: 5rem;
    max-width: 5rem;
    max-height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
      width: 100% !important;
    }
    img {
      object-fit: contain;
    }
  }
  .SectionButtonGridButtonLabel {
    .button-text();
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
    height: 2.25rem;
    padding: 0 0.75rem;
    font-family: @font-family-headings;
    border-radius: 0.5rem;
    color: @color-primary-900;
    background: @color-white;
    cursor: pointer;
    transition: @transition-all;
    white-space: nowrap;
    width: fit-content;
    margin: 1rem auto 0;
  }

  &.DataProviders {
    gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-rows: 1fr;

    @media @tablet {
      gap: 1rem;
    }
    .SectionButtonGridButton {
      padding: 0.5rem 0;
      box-shadow: @box-shadow-default;
      border: none;
      position: relative;

      .SectionHeading {
        .text-heading-4();
        margin: 0;
        padding: 0 1rem 0.5rem;
        color: @color-primary-900;
      }
      &.Attom {
        background: #666;
        .SectionHeading {
          color: @color-white;
        }
      }
      &.Powerlytics {
        background: #0c0b2b;
        .SectionHeading {
          color: @color-white;
        }
      }
      &.Seon {
        background: #0cb9ae;
        .SectionHeading {
          color: @color-white;
        }
      }
      &.Equifax {
        background: #981e32;
        .SectionHeading {
          color: @color-white;
        }
      }
      &.Ekata {
        background: #3f3694;
        .SectionHeading {
          color: @color-white;
        }
      }
      &.Experian {
        background: #f9f9f9;
      }
      &.Transunion {
        background: #20a3c6;
        .SectionHeading {
          color: @color-white;
        }
      }
      &.HouseCanary {
        background: #00242b;
        .SectionHeading {
          color: @color-white;
        }
      }
      &.Telesign {
        background: #0052e8;
        .SectionHeading {
          color: @color-white;
        }
      }
      &.Pipl {
        background: #4796e9;
        .SectionHeading {
          color: @color-white;
        }
      }
      &:after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background: transparent;
        transition: @transition-all;
        border-radius: @card-border-radius;
      }
      &:hover {
        &:after {
          background: rgba(@color-black, 0.1);
        }
      }
    }
    .SectionButtonGridButtonIcon {
      background: transparent;
      flex: 0 0 4rem;
      width: 4rem;
      height: 4rem;
      max-width: 4rem;
      max-height: 4rem;
      padding: 0.25rem;

      @media @tablet {
        flex: 0 0 3rem;
        width: 3rem;
        height: 3rem;
        max-width: 3rem;
        height: 3rem;
      }
    }
  }

  &.UseCases {
    grid-template-columns: 1fr 1fr;
    padding-left: 12rem;
    padding-right: 12rem;
    gap: 3rem;
    row-gap: 4rem;

    @media @desktop-small {
      padding-left: 3rem;
      padding-right: 3rem;
      column-gap: 2rem;
    }
    @media @tablet-landscape {
      grid-template-columns: 1fr 1fr;
      padding: 0;
      gap: 2rem;
    }

    @media @tablet {
      grid-template-columns: 1fr;
      padding: 0;
    }
    .SectionButtonGridButton {
      padding: 0;
      background: transparent;
      border-color: transparent;
      transition: @transition-all;

      &:hover {
        background-color: transparent;
        border-color: transparent;
        .SectionHeading,
        .SectionParagraph {
          color: @color-indigo-300;
        }
      }
      > span {
        flex-direction: row;
        text-align: left;
        gap: 1.5rem;
      }
    }
    .SectionButtonGridButtonIcon {
      background: transparent;
      flex: 0 0 3rem;
      width: 3rem;
      height: 3rem;
      max-width: 3rem;
      max-height: 3rem;
      padding: 0;
    }
    .SectionButtonGridContent {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .SectionHeading {
        color: @color-white;
        margin: 0;
      }
      .SectionParagraph {
        color: @color-violet-100;
        margin: 0;
      }
    }
  }
}

.SectionCallout {
  .card-large-Layout();
  box-shadow: @box-shadow-default;
  each(@colorSet, {
    &.@{key} { background-color: @value;}
  });

  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  text-align: left;
  gap: 4rem;

  .SectionHeading,
  .SectionParagraph {
    color: @color-white;
  }

  .SectionSubheading {
    color: @color-violet-100;
  }

  > .SectionHeading,
  > .SectionParagraph {
    margin: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media @tablet-landscape {
      align-items: center;
    }
  }

  @media @tablet-landscape {
    flex-direction: column;
    text-align: center;
    gap: 3.75rem;
  }

  @media @mobile {
    align-items: flex-start;
    text-align: left;
  }
}

.SectionColWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.SectionCol {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.SectionBlogThumbnails {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  text-align: left;

  @media @tablet-landscape {
    grid-template-columns: 1fr;
    gap: 1.25rem;
  }

  &.SectionBlogThumbnailsAlternate {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
}

.SectionWebinarThumbnails {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  gap: 3rem;

  @media @tablet-landscape {
    gap: 1.25rem;
  }
}

.SectionBigNumber {
  .card-medium-Layout();
  height: 100%;
  border-color: transparent;
  color: @color-white;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: @box-shadow-active;

  each(@colorSet, {
    &.@{key} { background-color: @value;}
  });

  .SectionBigNumberIcon {
    each(@colorSet, {
      &.@{key} { color: @value;}
    });

    font-size: 1.5rem;
    // margin-bottom: ;
  }

  .SectionBigNumberNumber {
    .text-heading-1();
    line-height: 1;
    color: @color-white;
    margin-bottom: 1rem;
    .SectionBigNumberAbbr {
      font-size: 66.6667%;
      margin-left: 0.25rem;
    }
  }

  .SectionBigNumberSubTitle {
    color: @color-white;
    margin: 0.25rem 0;
    font-size: 1.25rem;
    font-weight: 500;
  }

  .SectionBigNumberDescription {
    .text-paragraph();
    color: @color-white;
    font-family: inherit;
    margin: 0;
    font-weight: 400;
  }
}

.SectionImage {
  &.Responsive {
    height: 100%;
    width: 100%;
  }
}
