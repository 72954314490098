@import (reference) "../../styles/main";

.CtaLinkButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  width: 100%;

  @media @tablet-landscape {
    flex-direction: column;
  }
}

.Button,
.LinkButton {
  .button-text();
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.75rem;
  padding: 0 1.25rem;
  border: none;
  outline: none;
  color: @color-white;
  box-shadow: @box-shadow-default;
  border-radius: 0.5rem;
  background: @color-primary-900;
  cursor: pointer;
  transition: @transition-all;
  white-space: nowrap;
  &:hover {
    background: @color-primary-1000;
    box-shadow: @box-shadow-active;
    transition-duration: @transition-duration / 2;
  }

  &:active {
    background: @color-primary-1000;
  }

  &:focus {
    outline: 0.1975rem solid @color-indigo-300;
    outline: 0.1975rem solid @color-indigo-300;
  }

  img {
    margin-left: 0.75rem;
    filter: brightness(1) saturate(0) invert(1);
  }

  &:disabled {
    background-color: @color-coolGray-200!important;
    color: @color-coolGray-400!important;
    box-shadow: none !important;
    cursor: not-allowed;
    pointer-events: none;

    img {
      filter: grayscale(100%);
      opacity: 0.25;
    }
  }

  @media @tablet {
    width: 100%;
  }
}

.Size_lg {
  padding: 0 1.75rem;
}

.Size_md {
  height: 3rem;
  padding: 0 1rem;
}

.Size_sm {
  height: 2.25rem;
  padding: 0 0.75rem;
}
.Inverted {
  background: @color-white;
  color: @color-primary-900;

  img {
    filter: none;
  }

  &:hover,
  &:active {
    color: @color-primary-1000;
    background: @color-coolGray-300;
  }
}

.Text {
  background-color: transparent;
  color: @color-primary-900;
  box-shadow: none;

  &:hover,
  &:active {
    color: @color-primary-1000;
    background-color: transparent;
    box-shadow: none;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    background-color: transparent
  }
}

.BookDemoButton {
  width: 223px;
  height: 48px;

  @media @mobile {
    width: 100%;
  }
}

.Secondary {
  background: @color-teal-400;
  color: @color-primary-900;
  img {
    filter: none;
  }
  &:hover,
  &:active {
    color: @color-primary-1000;
    background: @color-teal-700;
  }
}

.Secondary_alt {
  background: @color-teal-100;
  color: @color-primary-900;
  box-shadow: none !important;
  img {
    filter: none;
  }
  &:hover,
  &:active {
    color: @color-primary-1000;
    background: @color-teal-400;
  }
}

.AutoWidth {
  width: fit-content;
  @media @tablet {
    width: 100% !important;
  }
}

.Disabled {
  background-color: @color-coolGray-200!important;
  color: @color-coolGray-400!important;
  box-shadow: none !important;
  cursor: not-allowed;
  pointer-events: none;

  img {
    filter: grayscale(100%);
    opacity: 0.25;
  }
}
